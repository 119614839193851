<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col align-self="center">
        <span class="mr-5 text-subtitle-1 text-sm-h6">点検票送信</span>
        <span
          class="text-caption text-sm-subtitle-1 text--secondary"
          :style="$vuetify.breakpoint.xs ? 'margin-bottom: 2px' : ''"
        >
          {{ businessDate }}
        </span>
      </v-col>
    </v-row>

    <!-- 点検票 -->
    <div v-if="loading" class="pa-4 text-body-2 text--secondary">点検票読み込み中・・・</div>
    <v-card v-else class="mb-8 mb-sm-4 pa-2">
      <v-row class="mb-4 pa-4" no-gutters>
        <v-col class="d-flex align-center flex-wrap" cols="12">
          <span class="mr-2 text-body-2">点検タイミング</span>
          <v-chip
            class="px-2 mr-5"
            color="required"
            outlined
            small
            style="margin-bottom: 2px; height: 20px"
          >
            必須
          </v-chip>
          <v-btn-toggle class="transparent d-flex flex-wrap" v-model="timing" color="primary" dense>
            <v-btn value="開始" :small="$vuetify.breakpoint.xs">開始</v-btn>
            <v-btn value="途中" :small="$vuetify.breakpoint.xs">途中</v-btn>
            <v-btn value="終了" :small="$vuetify.breakpoint.xs">終了</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                <span class="mr-4">記録内容</span>
                <v-btn
                  v-if="
                    inputtableItem.carName || inputtableItem.pointName || inputtableItem.mileage
                  "
                  class="mb-1 primary--text"
                  small
                  depressed
                  :disabled="isLoadedResult"
                  @click="loadLastResult()"
                >
                  前回の結果を反映
                </v-btn>
              </th>
              <th class="text-center" style="width: 200px">記入</th>
            </tr>
          </thead>

          <tbody>
            <!-- 運転日報用点検項目 -->
            <template v-for="item in drivingCheckItems">
              <tr
                v-if="item.isUse"
                :key="item.value"
                :class="
                  existsEmpty &&
                  item.required &&
                  ($data[item.value] === '' || $data[item.value] == null)
                    ? 'bg-required'
                    : ''
                "
              >
                <td>
                  <p>運転日報</p>
                  <span>{{ item.text }}</span>
                  <v-chip v-if="item.required" class="ml-2 px-1" outlined color="required" x-small>
                    必須
                  </v-chip>
                </td>
                <td v-if="item.value == 'carName'">
                  <v-combobox
                    class="ma-0 pa-0"
                    v-model="carName"
                    :items="sheetData.cars"
                    item-text="name"
                    item-value="name"
                    :return-object="false"
                    hide-details
                    height="38px"
                    dense
                  >
                    <template #item="{ item }">
                      <v-list-item-content class="border">
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle v-if="item.remarks" class="text-caption">
                          {{ item.remarks }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-combobox>
                </td>
                <td v-if="item.value == 'destinationName'">
                  <v-combobox
                    class="listInput ma-0 pa-0"
                    v-model="destinationName"
                    :items="sheetData.checkPoints"
                    item-text="name"
                    item-value="name"
                    :return-object="false"
                    hide-details
                    height="38px"
                    dense
                  >
                    <template #item="{ item }">
                      <v-list-item-content class="border">
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle v-if="item.remarks" class="text-caption">
                          {{ item.remarks }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-combobox>
                </td>
                <td v-if="item.value == 'meter'">
                  <InputNumber
                    :menuProps="{ left: true, offsetX: true }"
                    :value.sync="meter"
                    unit="km"
                    :digit="6"
                    :decimalDigit="1"
                    height="38"
                  />
                </td>
                <td v-if="item.value == 'mileage'">
                  <InputNumber
                    :menuProps="{ left: true, offsetX: true }"
                    :value.sync="mileage"
                    unit="km"
                    :digit="6"
                    :decimalDigit="1"
                    height="38"
                  />
                </td>
                <td v-if="item.value == 'refillQuantity'">
                  <InputNumber
                    :menuProps="{ left: true, offsetX: true }"
                    :value.sync="refillQuantity"
                    unit="L"
                    :digit="3"
                    height="38"
                  />
                </td>
              </tr>
            </template>

            <!-- 必須点検項目 -->
            <tr
              v-for="(item, i) in checkItems"
              :key="i"
              :class="
                item.checkResult == 'NG'
                  ? 'red lighten-5'
                  : existsEmpty && item.required && item.checkResult == ''
                  ? 'bg-required'
                  : ''
              "
            >
              <td>
                <span>{{ item.content }}</span>
                <v-chip v-if="item.required" class="ml-2 px-1" outlined color="required" x-small>
                  必須
                </v-chip>
              </td>
              <td class="d-flex justify-center" :class="item.required ? 'pr-13' : ''">
                <InputButtonToggle :value.sync="item.checkResult" :required="item.required" />
              </td>
            </tr>

            <!-- アルコールチェック用点検項目 -->
            <template v-for="item in alcoholCheckItems">
              <tr
                v-if="item.isUse"
                :key="item.value"
                :class="
                  existsEmpty &&
                  item.required &&
                  ($data[item.value] === '' || $data[item.value] == null)
                    ? 'bg-required'
                    : ''
                "
              >
                <td>
                  <span>{{ item.text }}</span>
                  <v-chip v-if="item.required" class="ml-2 px-1" outlined color="required" x-small>
                    必須
                  </v-chip>
                </td>
                <td v-if="item.value == 'pointName'">
                  <v-combobox
                    class="ma-0 pa-0"
                    v-model="pointName"
                    :items="sheetData.checkPoints"
                    item-text="name"
                    item-value="name"
                    :return-object="false"
                    hide-details
                    height="38px"
                    dense
                  >
                    <template #item="{ item }">
                      <v-list-item-content class="border">
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle v-if="item.remarks" class="text-caption">
                          {{ item.remarks }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-combobox>
                </td>
                <td v-if="item.value == 'checkMethod'" class="text-center">
                  <v-btn-toggle
                    v-model="checkMethod"
                    :mandatory="item.required && !!checkMethod"
                    color="primary"
                    dense
                  >
                    <v-btn value="対面" small>対面</v-btn>
                    <v-btn value="電話" small>電話</v-btn>
                    <v-btn value="他" small>他</v-btn>
                  </v-btn-toggle>
                </td>
                <td v-if="item.value == 'concentration'">
                  <InputNumber
                    :menuProps="{ left: true, offsetX: true }"
                    :value.sync="concentration"
                    height="38"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider class="mb-4" />

      <!-- 完了メール送信 -->
      <v-row class="px-4 pt-2" no-gutters justify="end">
        <v-col class="pr-4 d-flex justify-sm-end align-center" cols="12" sm="4" md="3">
          <span class="mr-2 text-body-2">完了メールを送信する</span>
          <v-switch
            class="ma-0 pa-0"
            v-model="shouldSendMail"
            hide-details
            color="primary"
            :ripple="false"
          />
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <v-menu offset-y nudge-top="20" left>
            <template v-slot:activator="{ on, attrs }">
              <v-combobox
                ref="mailInput"
                v-bind="attrs"
                v-on="on"
                v-model="emailDestination"
                item-text="mailAddress"
                :return-object="false"
                label="送信先"
                :rules="[
                  (v) => !!v || '必須入力です',
                  (v) => /.+@.+\..+/.test(v) || '有効なメールアドレスではありません',
                ]"
                :disabled="!shouldSendMail"
              />
            </template>
            <v-card height="300">
              <v-list dense>
                <template v-if="lastDestination.emailAddress">
                  <v-subheader class="pl-4">最新の点検結果の送信先</v-subheader>
                  <v-list-item-group v-model="emailDestination" color="primary">
                    <v-list-item :value="lastDestination.emailAddress">
                      <v-list-item-content>
                        <v-list-item-title>{{ lastDestination.emailAddress }}</v-list-item-title>
                        <v-list-item-subtitle class="text-caption">
                          送信日時：{{ formatDate(lastDestination.sentAt, "YYYY/MM/DD HH:mm") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                  <v-divider />
                </template>
                <v-subheader class="pl-4">店舗のユーザー</v-subheader>
                <v-list-item-group v-model="emailDestination" color="primary">
                  <v-list-item v-for="(item, i) in mailUsers" :key="i" :value="item.mailAddress">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle class="text-caption">
                        {{ item.mailAddress }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <!-- 特記事項・画像添付 -->
      <v-row no-gutters>
        <v-col cols="12" :sm="inputtableItem.camera == false ? 12 : 6">
          <div class="px-4 pb-2 d-flex align-end" style="height: 48px">
            <span class="text-body-2">特記事項</span>
          </div>
          <v-textarea
            v-model="comment"
            placeholder="入力してください"
            hide-details
            no-resize
            outlined
            height="120"
          />
        </v-col>
        <v-col v-if="inputtableItem.camera != false" class="pl-sm-4" cols="12" sm="6">
          <div class="px-4 pb-2 d-flex align-end" style="height: 48px">
            <span class="text-body-2">検知結果を撮影する</span>
            <v-chip
              v-if="requiredItem.camera"
              class="ml-2 px-1"
              outlined
              color="required"
              x-small
              style="margin-bottom: 2px"
            >
              必須
            </v-chip>
            <v-btn
              class="white ml-4"
              icon
              color="primary"
              elevation="2"
              @click.stop="$refs.fileInput.click()"
            >
              <v-icon>mdi-image-plus</v-icon>
            </v-btn>
            <input
              class="d-none"
              ref="fileInput"
              type="file"
              accept="image/jpeg, image/jpg, image/png"
              @input="selectImg"
            />
          </div>

          <v-sheet
            class="sheet-img px-2 pt-2 d-flex flex-wrap"
            :color="requiredItem.camera && existsEmpty && imgFiles.length == 0 ? 'bg-required' : ''"
            rounded
            height="120"
            @click="$refs.fileInput.click()"
          >
            <template v-if="imgFiles.length == 0">
              <div class="pa-2 text-body-2 text--secondary">
                カメラで撮影、もしくは画像を選択してください
              </div>
            </template>
            <template v-else>
              <div class="pa-2 d-flex align-start" v-for="(file, i) in imgFiles" :key="i">
                <v-img
                  class="rounded-lg"
                  :src="file.url"
                  width="80"
                  aspect-ratio="1"
                  @click.stop="$refs.dialogImg.openDialog(i)"
                  style="border: solid 1px #bdbdbd"
                />
                <v-icon
                  class="ml-n3 mt-n3 white grey--text text--darken-2"
                  style="border-radius: 50%"
                  @click.stop="
                    imgFiles.splice(i, 1);
                    $refs.fileInput.value = '';
                  "
                >
                  mdi-close-circle
                </v-icon>
              </div>
            </template>
          </v-sheet>
        </v-col>
      </v-row>

      <div v-if="existsEmpty" class="px-4 pt-2">
        <span class="required--text text-body-2">未入力項目があります</span>
      </div>
    </v-card>

    <!-- 送信ボタン -->
    <v-row class="px-4" no-gutters justify="end">
      <ButtonSendResult
        :loading="sending"
        :isNormal="checkItems.every((e) => e.checkResult != 'NG')"
        :disabled="
          sending ||
          !timing ||
          (shouldSendMail && !emailDestination) ||
          (shouldSendMail && !/.+@.+\..+/.test(emailDestination))
        "
        @click="sendCheckResult()"
      />
    </v-row>

    <v-snackbar v-model="snackbar" timeout="3000" color="primary" centered text>
      <div class="text-center">{{ snackbarText }}</div>
    </v-snackbar>
    <DialogImg ref="dialogImg" :files="imgFiles" />
    <DialogSendError ref="sendErrorDialog" />
    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-container>
</template>

<script>
import moment from "moment";
import firebase from "../../plugins/firebase";
import { db } from "../../plugins/firebase";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import uploadStorage from "cumin-common/src/mixins/uploadStorage";
import DialogImg from "../organisms/DialogImg";

export default {
  components: {
    DialogImg,
  },
  mixins: [calcDate, dbProcess, uploadStorage],
  data: () => ({
    timerID: null,
    businessDate: "",
    loading: false,
    isLoadedResult: false,
    sheetData: {},
    inputtableItem: {},
    requiredItem: {},
    drivingCheckItems: [
      { text: "車両名を入力", value: "carName" },
      { text: "行先を入力", value: "destinationName" },
      { text: "点検時メーターを入力", value: "meter" },
      { text: "走行距離を入力", value: "mileage" },
      { text: "給油量を入力", value: "refillQuantity" },
    ],
    alcoholCheckItems: [
      { text: "点検場所を入力", value: "pointName" },
      { text: "確認方法を入力", value: "checkMethod" },
      { text: "アルコール濃度を入力", value: "concentration" },
    ],
    timing: "",
    carName: "",
    pointName: "",
    destinationName: "",
    mileage: "",
    meter: "",
    refillQuantity: "",
    checkMethod: "",
    concentration: "",
    checkItems: [],
    shouldSendMail: false,
    emailDestination: "",
    lastDestination: {},
    mailUsers: [],
    comment: "",
    imgFiles: [],
    existsEmpty: false,
    sending: false,
    message: "",
    messageDialog: false,
    snackbar: false,
    snackbarText: "",
  }),
  created: async function () {
    this.$emit("created");
    this.logEvent("app_connect");

    this.businessDate = this.calculateBusinessDate(new Date());
  },
  activated: async function () {
    await this.loadData();
    await this.loadLastMailDestination();

    const setDate = () => (this.businessDate = this.calculateBusinessDate(new Date()));
    this.timerID = setInterval(setDate, 60000);
    this.existsEmpty = false;
  },
  deactivated: function () {
    clearInterval(this.timerID);
  },
  methods: {
    /**
     * DBから点検内容を取得
     */
    async loadData() {
      this.loading = true;

      const shop = this.$store.getters.getShop;

      // DBから帳票設定を取得
      const sheets = await this.getQueryDoc({
        collection: "alcoholCheckSheets",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
      });
      this.sheetData = sheets[0] ? sheets[0] : {};

      // 任意入力の点検項目を取得
      this.inputtableItem = this.sheetData.inputtableItem ? this.sheetData.inputtableItem : {};
      this.requiredItem = this.sheetData.requiredItem ? this.sheetData.requiredItem : {};
      for (const item of this.drivingCheckItems) {
        item.isUse = !!this.inputtableItem[item.value];
        item.required = !!this.requiredItem[item.value];
      }
      for (const item of this.alcoholCheckItems) {
        item.isUse = !!this.inputtableItem[item.value];
        item.required = !!this.requiredItem[item.value];
      }

      // 編集可能な点検票を取得
      this.checkItems = this.sheetData.checkItems
        ? this.sheetData.checkItems.map((item) => ({ ...item, checkResult: "" }))
        : [
            { content: "酒気帯びの有無", checkResult: "" },
            { content: "日常点検の実施状況", checkResult: "" },
            { content: "疾病、疲労、睡眠不足等の状況", checkResult: "" },
          ];

      // 完了メールの送信先リストを作成
      this.mailUsers = await this.getQueryDoc({
        collection: "users",
        where: [
          { fieldPath: "shopUID", opStr: "==", value: shop.shopUID },
          { fieldPath: "mailAddress", opStr: "!=", value: "" },
        ],
      });

      this.loading = false;
    },

    /**
     * 最新の点検結果を取得
     */
    async loadLastResult() {
      this.isLoadedResult = true;
      const shop = this.$store.getters.getShop;
      const user = this.$store.getters.getUser;

      const results = await this.getQueryDoc({
        collection: "alcoholCheckResults",
        where: [
          { fieldPath: "shopUID", opStr: "==", value: shop.shopUID },
          { fieldPath: "userUID", opStr: "==", value: user.userUID },
        ],
        order: [{ fieldPath: "sentAt", directionStr: "desc" }],
        limit: 1,
      });
      if (results.length == 1) {
        this.carName = this.sheetData.inputtableItem.carName ? results[0].carName : "";
        this.pointName = this.sheetData.inputtableItem.pointName ? results[0].pointName : "";
        this.meter = this.sheetData.inputtableItem.meter ? results[0].meter : "";

        this.snackbarText = `${this.formatDate(
          results[0].sentAt,
          "YYYY/MM/DD HH:mm"
        )} の点検結果を反映しました`;
        this.snackbar = true;
      }
    },

    /**
     * 最新の点検結果をメール宛先を取得
     */
    async loadLastMailDestination() {
      const shop = this.$store.getters.getShop;
      const user = this.$store.getters.getUser;

      const results = await this.getQueryDoc({
        collection: "alcoholCheckResults",
        where: [
          { fieldPath: "shopUID", opStr: "==", value: shop.shopUID },
          { fieldPath: "userUID", opStr: "==", value: user.userUID },
        ],
        order: [
          { fieldPath: "sentAt", directionStr: "desc" },
          { fieldPath: "emailDestination", directionStr: "asc" },
        ],
        limit: 1,
      });
      this.lastDestination = results[0]
        ? {
            emailAddress: results[0].emailDestination,
            sentAt: results[0].sentAt,
          }
        : {};
    },

    /**
     * 画像ファイルの選択
     */
    async selectImg() {
      const file = this.$refs.fileInput.files[0];
      const data = await this.selectFile(file);
      this.imgFiles.push({
        fileName: file.name,
        url: data.url,
        compressedFile: data.compressedFile,
      });
    },

    /**
     * 点検結果送信
     */
    async sendCheckResult() {
      // 必須確認
      this.existsEmpty =
        Object.keys(this.inputtableItem).some((key) => {
          if (key == "camera") return false;
          return this.inputtableItem[key] && this.requiredItem[key] && this[key] === "";
        }) ||
        this.checkItems.some((e) => e.checkResult === "" && !!e.required) ||
        (this.inputtableItem.camera && this.requiredItem.camera && this.imgFiles.length == 0);
      if (this.existsEmpty) return;

      // オフライン時の処理
      if (!navigator.onLine) return this.$refs.sendErrorDialog.open("offline");

      this.sending = true;

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const sendDate = new Date(this.businessDate + " 00:00:00");
      const registeredAt = firebase.firestore.Timestamp.fromDate(sendDate);
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      const resultId = this.createDocId("alcoholCheckResults");
      const sendData = {
        userUID: user.userUID,
        shopUID: shop.shopUID,
        timing: this.timing,
        checkItems: this.checkItems,
        comment: this.comment,
        imgFiles: [],
        registeredAt,
        sentAt: serverTimestamp,
        sender: user.name,
        confirmedAt: "",
        confirmerName: "",
        approvedAt: "",
        approverName: "",
        createdAt: serverTimestamp,
        updatedAt: serverTimestamp,
      };

      // 任意入力の結果
      for (const key of Object.keys(this.inputtableItem)) {
        if (this.inputtableItem[key] && this[key] !== undefined) {
          sendData[key] = ["mileage", "meter", "refillQuantity", "concentration"].includes(key)
            ? !isNaN(Number(this[key])) && this[key] !== ""
              ? Number(this[key])
              : ""
            : this[key];
        }
      }

      // 完了メール送信先
      if (this.shouldSendMail) sendData.emailDestination = this.emailDestination;

      // 画像をアップロード
      for (const [i, file] of Object.entries(this.imgFiles)) {
        // ファイルのパスを生成
        const fileDate = this.businessDate.replace(/\//g, "-");
        const fileName = `${("00" + (i + 1)).slice(-2)}.jpg`;
        const path = `alcoholCheckFile/${shop.shopUID}/${fileDate}/${resultId}/${fileName}`;
        const uploadResult = await this.uploadFile({ path, file: file.compressedFile });

        // 送信失敗の場合
        if (uploadResult.status == "error") {
          const type =
            uploadResult.code === "storage/retry-limit-exceeded" ? "unstable" : "unexpected";
          this.$refs.sendErrorDialog.open(type);
          this.sending = false;
          return;
        }

        sendData.imgFiles.push({ fileName: file.fileName, url: uploadResult.url });
      }

      const writeItems = [
        {
          method: "set",
          collection: "alcoholCheckResults",
          docId: resultId,
          data: sendData,
        },
      ];

      // 設定情報の更新
      const newSetting = await this.updateSetting();
      if (newSetting)
        writeItems.push({
          method: newSetting.isCreate ? "set" : "update",
          collection: "alcoholCheckSheets",
          docId: newSetting.docId,
          data: newSetting.data,
        });

      // DB登録
      const result = await this.writeTransaction(writeItems);

      // 登録失敗の場合
      if (result.status !== "success") {
        const type = result.message == "Connection failed." ? "unstable" : "unexpected";
        this.$refs.sendErrorDialog.open(type);
        this.logEvent("send_check_list", { message: "点検票の送信に失敗しました。" });
        this.sending = false;
        return;
      }

      // 設定を新規作成した場合
      if (newSetting?.isCreate) this.sheetData.id = newSetting.docId;

      // 点検完了メールの送信
      if (this.shouldSendMail) await this.sendCompleteMail();

      // 点検票初期化
      this.imgFiles = [];
      this.comment = "";
      this.timing = "";
      this.carName = "";
      this.pointName = "";
      this.destinationName = "";
      this.mileage = "";
      this.meter = "";
      this.refillQuantity = "";
      this.checkMethod = "";
      this.concentration = "";
      this.isLoadedResult = false;
      this.shouldSendMail = false;
      this.$refs.mailInput.reset();
      await this.loadLastMailDestination();

      this.message = "送信しました。";
      this.messageDialog = true;
      this.logEvent("send_check_list", { message: this.message });

      this.sending = false;

      // NGメールの送信
      if (sendData.checkItems.some((e) => e.checkResult == "NG")) await this.sendNgMail();
    },

    /**
     * 設定情報の更新
     */
    async updateSetting() {
      if (!this.sheetData.cars) this.sheetData.cars = [];
      if (!this.sheetData.checkPoints) this.sheetData.checkPoints = [];

      // 車両の存在確認
      const carExists = this.sheetData.cars.some((e) => e.name == this.carName);
      if (!carExists && this.carName) this.sheetData.cars.push({ name: this.carName, remarks: "" });

      // 点検場所の存在確認
      const pointExists = this.sheetData.checkPoints.some((e) => e.name == this.pointName);
      if (!pointExists && this.pointName)
        this.sheetData.checkPoints.push({ name: this.pointName, remarks: "" });

      // 行先の存在確認
      const destinationExists = this.sheetData.checkPoints.some(
        (e) => e.name == this.destinationName
      );
      if (!destinationExists && this.destinationName)
        this.sheetData.checkPoints.push({ name: this.destinationName, remarks: "" });

      if (!carExists || !pointExists || !destinationExists) {
        const user = this.$store.getters.getUser;
        const shop = this.$store.getters.getShop;

        const isCreate = !this.sheetData.id;
        const docId = this.sheetData.id || db.collection("alcoholCheckSheets").doc().id;
        const sendData = {
          shopUID: shop.shopUID,
          checkPoints: this.sheetData.checkPoints,
          cars: this.sheetData.cars,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updaterName: user.name,
        };

        // 新規登録の場合
        if (isCreate) sendData.createdAt = firebase.firestore.FieldValue.serverTimestamp();

        return { isCreate, docId, data: sendData };
      }
    },

    /**
     * NGメール送信
     */
    async sendNgMail() {
      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;

      const functions = await firebase.app().functions("asia-northeast1");
      const sendMail = functions.httpsCallable("sendMail");

      const environment =
        process.env.NODE_ENV == "development" || process.env.NODE_ENV == "test" ? "[DEV]" : "";
      const subject =
        shop.shopUID == this.$route.params.shopUID
          ? `${environment}[ハレコード] アルコールチェックNGのお知らせ`
          : `${environment}[ハレコード][${shop.selectShopName}] アルコールチェックNGのお知らせ`;

      const mailResult = await sendMail({
        position: user.position,
        shopUID: shop.shopUID,
        subject: subject,
        text:
          "各位\n\n" +
          "アルコールチェックの結果にNGがありましたのでお知らせいたします。\n\n" +
          "【店舗名】\n" +
          `${shop.selectShopName}\n\n` +
          "【従業員名】\n" +
          `${user.name}\n\n` +
          "以下のURLからログインして内容のご確認をお願いいたします。\n" +
          `${window.location.origin}/${shop.shopUID}/check-summary\n\n` +
          "※このメールは送信専用のため、ご返信いただけません。\n\n\n" +
          "－－－－－－－－－－－－－－－－－－－－－－－－－－－－－－\n" +
          "ハレコードは株式会社ウエノフードテクノの商標です。\n",
      });

      if (mailResult.data.status == "error") {
        this.logEvent("error_function", {
          method_name: mailResult.data.method,
          error_message: mailResult.data.error.message,
        });
      }
    },

    /**
     * 実施したことをメール送信
     */
    async sendCompleteMail() {
      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const environment =
        process.env.NODE_ENV == "development" || process.env.NODE_ENV == "test" ? "[DEV]" : "";

      const functions = await firebase.app().functions("asia-northeast1");
      const sendTextMail = functions.httpsCallable("sendTextMail");
      const mailResult = await sendTextMail({
        to: this.emailDestination,
        subject: `${environment}[ハレコード] アルコールチェック実施のお知らせ`,
        text:
          "各位\n\n" +
          "アルコールチェックを実施しましたのでお知らせいたします。\n\n" +
          "【店舗名】\n" +
          `${shop.selectShopName}\n\n` +
          "【従業員名】\n" +
          `${user.name}\n\n` +
          "【点検日時】\n" +
          `${moment().format("YYYY/MM/DD HH:mm")}\n\n` +
          "以下のURLからログインして内容のご確認をお願いいたします。\n" +
          `${window.location.origin}/${shop.shopUID}/check-summary?date=${this.businessDate}\n\n` +
          "※このメールは送信専用のため、ご返信いただけません。\n\n\n" +
          "－－－－－－－－－－－－－－－－－－－－－－－－－－－－－－\n" +
          "ハレコードは株式会社ウエノフードテクノの商標です。\n",
      });

      if (mailResult.data.status == "error") {
        this.logEvent("error_function", {
          method_name: mailResult.data.method,
          error_message: mailResult.data.error.message,
        });
      }
    },
  },
};
</script>

<style scoped>
.v-data-table ::v-deep .v-data-table__wrapper table thead tr th,
.v-data-table ::v-deep .v-data-table__wrapper table tbody tr td {
  height: 40px;
}

::v-deep .v-list-item:not(:last-child):has(.border) {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  ::v-deep
  .v-input__control
  .v-input__slot {
  padding: 0 16px;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  ::v-deep
  .v-input__control
  > .v-input__slot
  fieldset {
  color: #bdbdbd !important;
}

.sheet-img {
  background-color: #f5f5f5;
  border: 1px solid #bdbdbd;
}

p {
  margin: 0;
  font-size: 0.5rem;
  color: grey;
}

::v-deep
  .v-input--switch:not(.v-input--switch--flat):not(.v-input--switch--inset)
  .v-input--switch__thumb {
  color: #fff !important;
}

::v-deep .v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
  color: #fff !important;
}
</style>
