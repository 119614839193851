<template>
  <v-card color="#f8f8f8">
    <v-card-title class="py-0" style="height: 60px; background-color: #f8f8f8">
      <span class="text-subtitle-1">帳票設定</span>
      <v-spacer></v-spacer>
      <v-btn
        class="text--secondary"
        width="100"
        text
        rounded
        :disabled="idDisabledButton"
        @click="
          editData.inputtableItem = JSON.parse(JSON.stringify(currentData.inputtableItem));
          editData.requiredItem = JSON.parse(JSON.stringify(currentData.requiredItem));
          editData.checkItems = currentData.checkItems.map((e) => ({ ...e }));
        "
      >
        キャンセル
      </v-btn>
      <v-btn
        class="ml-2"
        color="primary"
        width="100"
        rounded
        depressed
        :disabled="
          idDisabledButton ||
          editData.checkItems.length == 0 ||
          editData.checkItems.some((e) => !e.content) ||
          processing
        "
        :loading="processing"
        @click.stop="storeSetting()"
      >
        保存
      </v-btn>
    </v-card-title>

    <v-row no-gutters>
      <v-col class="mb-2 mb-sm-0 pa-2" cols="12" sm="6" md="5">
        <!-- 運転日報用点検項目 -->
        <p class="ml-2 mb-1 text-body-2">運転日報用点検項目</p>
        <v-card color="mb-6 pa-2" outlined>
          <v-data-table
            :headers="headers"
            :items="drivingCheckItems"
            :items-per-page="-1"
            :loading="loading"
            loading-text="読込中"
            no-data-text="データがありません"
            fixed-header
            hide-default-footer
            disable-sort
            :mobile-breakpoint="null"
            dense
          >
            <template #[`item`]="{ item }">
              <tr>
                <td>
                  <v-switch
                    class="ma-0 pa-0"
                    v-model="editData.inputtableItem[item.value]"
                    hide-details
                    color="primary"
                    :ripple="false"
                  />
                </td>
                <td :class="editData.inputtableItem[item.value] ? '' : 'grey--text'">
                  {{ item.text }}
                </td>
                <td>
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="editData.requiredItem[item.value]"
                    :ripple="false"
                    :disabled="!editData.inputtableItem[item.value]"
                    hide-details
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>

        <!-- アルコールチェック用点検項目 -->
        <p class="ml-2 mb-1 text-body-2">アルコールチェック用点検項目</p>
        <v-card color="pa-2" outlined>
          <v-data-table
            :headers="headers"
            :items="alcoholCheckItems"
            :items-per-page="-1"
            :loading="loading"
            loading-text="読込中"
            no-data-text="データがありません"
            fixed-header
            hide-default-footer
            disable-sort
            :mobile-breakpoint="null"
            dense
          >
            <template #[`item`]="{ item }">
              <tr>
                <td>
                  <v-switch
                    class="ma-0 pa-0"
                    v-model="editData.inputtableItem[item.value]"
                    hide-details
                    color="primary"
                    :ripple="false"
                  />
                </td>
                <td :class="editData.inputtableItem[item.value] ? '' : 'grey--text'">
                  {{ item.text }}
                </td>
                <td>
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="editData.requiredItem[item.value]"
                    :ripple="false"
                    :disabled="!editData.inputtableItem[item.value]"
                    hide-details
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <!-- 編集可能項目 -->
      <v-col class="pa-2">
        <p class="ml-2 mb-1 text-body-2">編集可能項目</p>
        <v-card color="pa-2" outlined>
          <v-data-table
            :items="editData.checkItems"
            :headers="[
              { text: '点検内容', value: 'content' },
              { text: '必須', value: 'required', width: 56 },
              { text: '', value: 'action', width: 68 },
            ]"
            :items-per-page="-1"
            :loading="loading"
            loading-text="読込中"
            no-data-text="データがありません"
            fixed-header
            hide-default-footer
            disable-sort
            :mobile-breakpoint="null"
            :height="checkTableHeight"
            dense
          >
            <template #[`item.content`]="{ item }">
              <v-text-field
                class="ma-0 pa-0 text-body-2"
                v-model="item.content"
                hide-details
                placeholder="必須入力"
                style="height: 38px"
              />
            </template>
            <template #[`item.required`]="{ item }">
              <v-checkbox class="ma-0 pa-0" v-model="item.required" :ripple="false" hide-details />
            </template>
            <template #[`header.action`]="">
              <v-btn icon @click.stop="editData.checkItems.push({ content: '' })">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <template #[`item.action`]="{ index }">
              <v-btn icon @click="editData.checkItems.splice(index, 1)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    editData: Object,
    currentData: Object,
    storeCheckSheet: Function,
  },
  data: () => ({
    headers: [
      { text: "利用", value: "isUse", width: 70 },
      { text: "項目名", value: "text" },
      { text: "必須", value: "required", width: 56 },
    ],
    drivingCheckItems: [
      { text: "車両名", value: "carName" },
      { text: "行先", value: "destinationName" },
      { text: "点検時メーター", value: "meter" },
      { text: "走行距離", value: "mileage" },
      { text: "給油量", value: "refillQuantity" },
    ],
    alcoholCheckItems: [
      { text: "点検場所", value: "pointName" },
      { text: "確認方法", value: "checkMethod" },
      { text: "アルコール濃度", value: "concentration" },
      { text: "検知結果の撮影", value: "camera" },
    ],
    processing: false,
  }),
  computed: {
    /**
     * テーブルの高さを取得
     * @return {number} 高さ
     */
    checkTableHeight() {
      const bp = this.$vuetify.breakpoint;
      if (bp.xs) return 300;
      const height = bp.height - 344;
      return height <= 506 ? 506 : height;
    },

    /**
     * 保存・キャンセルボタンを非活性にするか
     * @return {boolean} 結果
     */
    idDisabledButton() {
      const existsDiff = Object.keys(this.editData.inputtableItem).some(
        (key) =>
          this.editData.inputtableItem[key] != this.currentData.inputtableItem[key] ||
          this.editData.requiredItem[key] != this.currentData.requiredItem[key]
      );
      const existsItemsDiff =
        JSON.stringify(this.editData.checkItems) != JSON.stringify(this.currentData.checkItems);
      return !existsDiff && !existsItemsDiff;
    },
  },
  methods: {
    /**
     * 編集内容をDBに登録
     */
    async storeSetting() {
      this.processing = true;

      const storeData = {
        inputtableItem: JSON.parse(JSON.stringify(this.editData.inputtableItem)),
        requiredItem: JSON.parse(JSON.stringify(this.editData.requiredItem)),
        checkItems: this.editData.checkItems.map((e) => ({
          content: e.content,
          required: !!e.required,
        })),
      };

      // DB登録
      await this.storeCheckSheet(storeData);

      this.processing = false;
    },
  },
};
</script>

<style scoped>
::v-deep .v-input__control {
  height: 100%;
}

::v-deep .v-input__slot {
  height: 100%;
}

::v-deep .v-text-field__slot input {
  padding: 0;
}

::v-deep .v-input input::placeholder {
  color: red !important;
  opacity: 0.5;
}

.v-data-table ::v-deep .v-data-table__wrapper table thead tr,
.v-data-table ::v-deep .v-data-table__wrapper table tbody tr {
  height: 40px;
}

::v-deep
  .v-input--switch:not(.v-input--switch--flat):not(.v-input--switch--inset)
  .v-input--switch__thumb {
  color: #fff !important;
}

::v-deep .v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
  color: #fff !important;
}

::v-deep .v-input--selection-controls__input {
  margin-right: 0px !important;
}
</style>
