<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="pa-4">
        <span class="text-subtitle-1 text-sm-h6">点検結果詳細</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <!-- 詳細内容 -->
      <CheckResultDetail :result="item">
        <ButtonCreatePDF title="アルコールチェック" subTitle="点検結果詳細" icon="mdi-car">
          <CheckResultDetail :result="item" preview />
        </ButtonCreatePDF>
      </CheckResultDetail>
    </v-card>
  </v-dialog>
</template>

<script>
import CheckResultDetail from "./CheckResultDetail";

export default {
  components: {
    CheckResultDetail,
  },
  data: () => ({
    dialog: false,
    item: { imgFiles: [] },
  }),
  methods: {
    /**
     * ダイアログを開く
     * @param {Object} selectedItem 選択した清掃場所
     */
    openDialog(selectedItem) {
      this.item = selectedItem;
      this.dialog = true;
    },
  },
};
</script>
