<template>
  <v-dialog v-model="dialog" persistent>
    <v-card class="pa-2 pa-sm-4">
      <v-card-text class="pa-4 d-flex justify-space-between align-center">
        <span>{{ files[index] ? files[index].fileName : "" }}</span>
        <v-btn icon>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-btn>
      </v-card-text>
      <v-carousel
        v-if="dialog"
        v-model="index"
        hide-delimiters
        :show-arrows="files.length > 1"
        @change="loadImg(files[$event].url)"
        :height="getCarouselHeight"
      >
        <v-carousel-item v-for="(file, i) in files" :key="`card-${i}`" h>
          <div class="d-flex justify-center">
            <v-img
              :src="files[index].url"
              contain
              :max-width="getImgSize('width')"
              :max-height="getImgSize('height')"
            />
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    files: Array,
  },
  data: () => ({
    dialog: false,
    index: 0,
    imgSize: { width: 0, height: 0 },
  }),
  computed: {
    /**
     * スライド部分の高さを取得
     * @return {number} 高さ
     */
    getCarouselHeight() {
      return document.documentElement.clientHeight * 0.9 - 100;
    },

    /**
     * 画像の最大幅を取得
     * @param {string} 方向
     * @return {number} 幅
     */
    getImgSize() {
      return (direction) => {
        const max = {
          height: document.documentElement.clientHeight * 0.9 - 100,
          width: document.documentElement.clientWidth * 0.9,
        };
        return this.imgSize[direction] < max[direction] ? this.imgSize[direction] : max[direction];
      };
    },
  },
  methods: {
    /**
     * ダイアログを開く
     * @param {number} index 選択したファイル
     */
    openDialog(index) {
      this.index = index;
      this.loadImg(this.files[index].url);
      this.dialog = true;
    },

    /**
     * 画像ファイルの読み込み
     * @param {string} imgFileURL
     */
    loadImg(imgFileURL) {
      const image = new Image();
      image.src = imgFileURL;
      image.onload = () => {
        this.imgSize.width = image.width;
        this.imgSize.height = image.height;
      };
    },
  },
};
</script>

<style scoped>
::v-deep .v-carousel .v-window-item {
  display: flex;
  align-items: center;
}

::v-deep .v-carousel__item {
  height: auto !important;
}
</style>
