<template>
  <v-card color="#f8f8f8">
    <v-card-title class="py-0" style="height: 60px">
      <span class="text-subtitle-1">{{ title[itemKey] }}</span>
      <v-spacer></v-spacer>
      <v-btn
        class="text--secondary"
        width="100"
        text
        :disabled="idDisabledButton"
        @click="editData[itemKey] = currentData[itemKey].map((e) => ({ ...e }))"
      >
        キャンセル
      </v-btn>
      <v-btn
        class="ml-2"
        color="primary"
        width="100"
        rounded
        depressed
        :disabled="idDisabledButton || processing"
        :loading="processing"
        @click="storeSetting()"
      >
        保存
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="header[itemKey]"
      :items="editData[itemKey]"
      :items-per-page="-1"
      :loading="loading"
      loading-text="読込中"
      no-data-text="データがありません"
      fixed-header
      hide-default-footer
      disable-sort
      :mobile-breakpoint="null"
      :height="tableHeight"
      dense
    >
      <template v-slot:[`item.name`]="{ item, index }">
        <v-text-field
          class="ma-0 pa-0"
          :class="isEdittingItem('name', index) ? 'tertiary' : ''"
          v-model="item.name"
          placeholder="必須入力"
          hide-details
          height="39"
          style="min-width: 150px; font-size: 14px"
        />
      </template>
      <template v-slot:[`item.remarks`]="{ item, index }">
        <v-text-field
          class="ma-0 pa-0"
          :class="isEdittingItem('remarks', index) ? 'tertiary' : ''"
          v-model="item.remarks"
          hide-details
          height="39"
          style="min-width: 200px; font-size: 14px"
        />
      </template>

      <template v-slot:[`header.action`]="{}">
        <v-btn class="white" icon @click="editData[itemKey].push({ name: '', remarks: '' })">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.action`]="{ index }">
        <v-btn icon @click="editData[itemKey].splice(index, 1)">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    editData: Object,
    currentData: Object,
    itemKey: String,
    storeCheckSheet: Function,
  },
  data: () => ({
    title: { cars: "車両一覧", checkPoints: "登録地点一覧" },
    header: {
      cars: [
        { text: "車両名", value: "name", width: "40%" },
        { text: "備考", value: "remarks", width: "60%" },
        { text: "", value: "action", width: 60 },
      ],
      checkPoints: [
        { text: "地点名", value: "name", width: "40%" },
        { text: "備考", value: "remarks", width: "60%" },
        { text: "", value: "action", width: 60 },
      ],
    },
    processing: false,
  }),
  computed: {
    /**
     * データテーブルの高さ取得
     * @return {number} 高さ
     */
    tableHeight() {
      const bp = this.$vuetify.breakpoint;
      const height = bp.height - (bp.xs ? 294 : 286);
      return bp.height <= 500 ? 500 : height;
    },

    /**
     * セルが編集中かどうか
     * @param {string} フィールド名
     * @param {number} 行番号
     * @return {boolean} 結果
     */
    isEdittingItem() {
      return (key, i) => {
        if (!this.currentData[this.itemKey][i]) return true;
        return this.editData[this.itemKey][i][key] != this.currentData[this.itemKey][i][key];
      };
    },

    /**
     * 保存・キャンセルボタンを非活性にするか
     * @return {boolean} 結果
     */
    idDisabledButton() {
      if (!this.editData[this.itemKey]) return true;
      if (this.editData[this.itemKey].some((e) => e.name == "")) return true;
      if (this.editData[this.itemKey].length != this.currentData[this.itemKey].length) return false;
      return this.editData[this.itemKey].every((e, i) => {
        if (!this.currentData[this.itemKey][i]) return false;
        return (
          e.name == this.currentData[this.itemKey][i].name &&
          e.remarks == this.currentData[this.itemKey][i].remarks
        );
      });
    },
  },
  methods: {
    /**
     * DBの更新
     */
    async storeSetting() {
      this.processing = true;

      const storeData = {};
      storeData[this.itemKey] = this.editData[this.itemKey];

      // DB登録
      await this.storeCheckSheet(storeData);

      this.processing = false;
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table .v-data-table__wrapper table .v-data-table-header tr th {
  height: 48px !important;
  background-color: #f8f8f8 !important;
}

.v-data-table ::v-deep .v-data-table__wrapper table thead tr th,
.v-data-table ::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
  height: 40px !important;
}

.v-data-table ::v-deep .v-data-table__wrapper table thead tr th:first-child,
.v-data-table ::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 16px;
}

.v-data-table ::v-deep .v-data-table__wrapper table thead tr th:last-child,
.v-data-table ::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 16px;
}

::v-deep .v-input input::placeholder {
  color: red !important;
  font-size: 14px;
  opacity: 0.5;
}
</style>
