<template>
  <div class="text-caption text-sm-body-2">
    <div class="px-4 pb-4 d-flex">
      <div>
        <p>
          <span>点検結果：</span>
          <span :class="result.isNormalForReport ? '' : ngClass">{{
            result.isNormalForReport ? "OK" : "NG"
          }}</span>
        </p>
        <p class="d-flex">
          <span class="text-no-wrap">送信日時：</span>
          <span>{{ formatDate(result.sentAt, "YYYY/MM/DD HH:mm") }}（{{ result.sender }}）</span>
        </p>
        <p class="d-flex">
          <span class="text-no-wrap">確認日時：</span>
          <span>
            <span>{{ formatDate(result.confirmedAt, "YYYY/MM/DD HH:mm") }}</span>
            <span v-if="result.confirmerName">（{{ result.confirmerName }}）</span>
          </span>
        </p>
        <p class="d-flex">
          <span class="text-no-wrap">承認日時：</span>
          <span>
            <span>{{ formatDate(result.approvedAt, "YYYY/MM/DD HH:mm") }}</span>
            <span v-if="result.approverName">（{{ result.approverName }}）</span>
          </span>
        </p>
      </div>

      <v-spacer></v-spacer>
      <slot />
    </div>

    <!-- 運転日報結果 -->
    <template v-if="drivingCheckItems.some((item) => result[item.value] !== undefined)">
      <p class="mb-1 pl-4">運転日報結果</p>
      <v-card class="pa-2 mb-4" outlined>
        <v-data-table
          :headers="headers"
          :items="drivingCheckItems.filter((item) => result[item.value] !== undefined)"
          :items-per-page="-1"
          hide-default-footer
          disable-sort
          dense
          :mobile-breakpoint="null"
        >
          <template v-slot:[`item.content`]="{ item }">
            <span>{{ item.text }}</span>
          </template>
          <template v-slot:[`item.checkResult`]="{ item }">
            <span :class="result[item.value] === '' ? 'grey--text' : ''">{{
              result[item.value] !== "" ? `${result[item.value]}${item.unit || ""}` : "未点検"
            }}</span>
          </template>
        </v-data-table>
      </v-card>
    </template>

    <!-- 編集可能項目結果 -->
    <p class="mb-1 pl-4">詳細結果</p>
    <v-card class="pa-2 mb-4" outlined>
      <v-data-table
        :headers="headers"
        :items="result.checkItems"
        :items-per-page="-1"
        hide-default-footer
        disable-sort
        dense
        :mobile-breakpoint="null"
      >
        <template v-slot:[`item.checkResult`]="{ item }">
          <span
            :class="
              item.checkResult === 'NG' ? ngClass : item.checkResult === '' ? 'grey--text' : ''
            "
          >
            {{ item.checkResult || "未点検" }}
          </span>
        </template>
      </v-data-table>
    </v-card>

    <!-- アルコールチェック結果 -->
    <template v-if="alcoholCheckItems.some((item) => result[item.value] !== undefined)">
      <p class="mb-1 pl-4">アルコールチェック結果</p>
      <v-card class="pa-2 mb-4" outlined>
        <v-data-table
          :headers="headers"
          :items="alcoholCheckItems.filter((item) => result[item.value] !== undefined)"
          :items-per-page="-1"
          hide-default-footer
          disable-sort
          dense
          :mobile-breakpoint="null"
        >
          <template v-slot:[`item.content`]="{ item }">
            <span>{{ item.text }}</span>
          </template>
          <template v-slot:[`item.checkResult`]="{ item }">
            <span :class="result[item.value] === '' ? 'grey--text' : ''">{{
              result[item.value] !== "" ? `${result[item.value]}${item.unit || ""}` : "未点検"
            }}</span>
          </template>
        </v-data-table>
      </v-card>
    </template>

    <div class="px-4 mb-4 d-flex">
      <div class="text-no-wrap">特記事項：</div>
      <span>{{ result.comment ? result.comment : "なし" }}</span>
    </div>

    <p class="mb-1 pl-4">点検結果写真<span v-if="result.imgFiles.length == 0">：なし</span></p>
    <v-card v-if="result.imgFiles.length > 0" class="px-4 pt-2 d-flex flex-wrap" outlined>
      <div class="mr-2 mb-2" v-for="(file, i) in result.imgFiles" :key="i">
        <ImgThumbnail :src="file.url" size="100" @click="$refs.dialogImg.openDialog(i)" />
      </div>
    </v-card>

    <DialogImg ref="dialogImg" :files="result.imgFiles" />
  </div>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";
import DialogImg from "./DialogImg";

export default {
  props: {
    result: Object,
    preview: Boolean,
  },
  components: {
    DialogImg,
  },
  mixins: [calcDate],
  data: () => ({
    ngClass: "red--text font-weight-bold",
    drivingCheckItems: [
      { text: "車両名", value: "carName" },
      { text: "行先", value: "destinationName" },
      { text: "点検時メーター", value: "meter", unit: "km" },
      { text: "走行距離", value: "mileage", unit: "km" },
      { text: "給油量", value: "refillQuantity", unit: "L" },
    ],
    alcoholCheckItems: [
      { text: "点検場所", value: "pointName" },
      { text: "確認方法", value: "checkMethod" },
      { text: "アルコール濃度", value: "concentration" },
    ],
    headers: [
      { text: "点検内容", value: "content" },
      { text: "点検結果", value: "checkResult", align: "center", width: 80 },
    ],
  }),
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0;
}

::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 8px;
  height: 36px !important;
}
::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}
</style>
