<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters :style="{ height: $vuetify.breakpoint.xs ? '76px' : '60px' }">
      <v-col cols="12" sm="4" align-self="center">
        <span class="text-subtitle-1 text-sm-h6">点検結果一覧</span>
      </v-col>
      <v-col cols="9" sm="5" align-self="center">
        <DatePicker :date.sync="selectedDate" @pick="loadList()" />
      </v-col>
      <v-col class="text-end" align-self="center" cols="3">
        <DialogExportResult
          serviceName="アルコールチェック"
          :properties="exportProperties"
          :selectedDate="selectedDate"
        />
      </v-col>
    </v-row>

    <!-- 点検結果一覧 -->
    <v-row class="mb-4" no-gutters>
      <v-col>
        <!-- タブ部分 -->
        <v-tabs v-model="tab" show-arrows center-active @change="selected = []">
          <v-tab v-for="(position, i) in positions" :key="i">
            {{ position.text }}
            <v-avatar
              v-if="position.unapprovedCount > 0"
              color="red lighten-1"
              size="16"
              style="margin: 0 0 2px 2px"
            >
              <span class="white--text text-caption">{{ position.unapprovedCount }}</span>
            </v-avatar>
          </v-tab>
        </v-tabs>

        <!-- タブ内容 -->
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item v-for="position in positions" :key="position.text">
            <TableSummary
              :selected.sync="selected"
              :items="position.items"
              :headers="$vuetify.breakpoint.xs ? xsHeaders : headers"
              :filterHeader="filterHeader"
              :comments="[]"
              :loading="loading"
              :showSelect="true"
              sortBy="sentAt"
              :sortDesc="true"
              :tableHeight="getTableHeight"
              @clickRow="$refs.checkResultDialog.openDialog($event)"
            >
              <template v-slot:[`item.result`]="{ item }">
                <td>
                  <span class="mr-2">
                    <span v-if="item.isNormalForReport">OK</span>
                    <span v-else class="red--text font-weight-bold">NG</span>
                  </span>
                  <v-icon v-if="item.comment" size="20">mdi-comment-processing-outline</v-icon>
                  <v-icon v-if="item.imgFiles.length" size="20">mdi-file-image-outline</v-icon>
                </td>
              </template>
            </TableSummary>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- 承認ボタン -->
    <v-row no-gutters justify="end">
      <ButtonApproveResult
        :items="selected"
        :disabled="selected.length == 0"
        collection="alcoholCheckResults"
        @reload="loadList()"
      />
    </v-row>
    <DialogCheckResult ref="checkResultDialog" />
  </v-container>
</template>

<script>
import moment from "moment";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import DialogCheckResult from "../organisms/DialogCheckResult";

export default {
  components: {
    DialogCheckResult,
  },
  mixins: [calcDate, dbProcess],
  data: () => ({
    loading: false,
    selectedDate: "",
    users: [],
    tab: null,
    selected: [],
    positions: [],
    headers: [
      { text: "氏名", value: "sender", width: 150, class: "responsive" },
      { text: "点検タイミング", value: "timing", width: 120 },
      { text: "点検結果", value: "result", width: 86 },
      { text: "送信日時", value: "sentAt", width: 100 },
      { text: "確認日時", value: "confirmedAt", width: 100 },
      { text: "承認日時", value: "approvedAt", width: 112 },
    ],
    xsHeaders: [
      { text: "氏名", value: "sender", width: 100, class: "responsive" },
      { text: "タイミング", value: "timing", width: 100 },
      { text: "結果", value: "result", width: 74 },
      { text: "送信", value: "sentAt", width: 44 },
      { text: "確認", value: "confirmedAt", align: "center", width: 34 },
      { text: "承認", value: "approvedAt", align: "center", width: 46 },
    ],
    filterHeader: {},
    exportProperties: [
      {
        sheetName: "アルコールチェック結果",
        collection: "alcoholCheckResults",
        resultKey: "checkItems",
        resultColumns: [
          { header: "点検内容", key: "content" },
          { header: "点検結果", key: "checkResult" },
        ],
        columns: [
          { header: "送信日時", key: "sentAt" },
          { header: "氏名", key: "sender" },
          { header: "点検タイミング", key: "timing" },
          { header: "車両名", key: "carName" },
          { header: "行先", key: "destinationName" },
          { header: "点検時メーター", key: "meter" },
          { header: "走行距離", key: "mileage" },
          { header: "給油量", key: "refillQuantity" },
          { header: "点検結果", key: "checkItems" },
          { header: "点検場所名", key: "pointName" },
          { header: "確認方法", key: "checkMethod" },
          { header: "アルコール数値", key: "concentration" },
          { header: "コメント", key: "comment" },
          { header: "確認日時", key: "confirmedAt" },
          { header: "確認者", key: "confirmerName" },
          { header: "承認日時", key: "approvedAt" },
          { header: "承認者", key: "approverName" },
        ],
      },
    ],
  }),
  created: async function () {
    this.$emit("created");
    const queryDate = this.$route.query.date;
    this.selectedDate = queryDate ? queryDate : this.calculateBusinessDate(new Date());

    await this.loadUsers();
  },
  activated: async function () {
    await this.loadList();
  },
  computed: {
    /**
     * データテーブルの高さ取得
     * @return {number} 高さ
     */
    getTableHeight() {
      const bp = this.$vuetify.breakpoint;
      const height = bp.height - (bp.xs ? 300 : 334);
      return height <= 300 ? 300 : height;
    },
  },
  methods: {
    /**
     * 従業員リストを取得
     */
    async loadUsers() {
      const shop = this.$store.getters.getShop;
      this.users = await this.getQueryDoc({
        collection: "users",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
      });
    },

    /**
     * 回答リストを取得
     */
    async loadList() {
      this.loading = true;

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const isMonthly = this.selectedDate.length == 7;

      const startAt = isMonthly
        ? new Date(`${this.selectedDate}/01 00:00:00`)
        : new Date(`${this.selectedDate} 00:00:00`);
      const endAt = isMonthly
        ? new Date(moment(startAt).add(1, "month").add(-1, "seconds"))
        : new Date(`${this.selectedDate} 23:59:59`);

      const results = await this.getQueryDoc({
        collection: "alcoholCheckResults",
        where:
          user.authority == "general"
            ? [
                { fieldPath: "userUID", opStr: "==", value: user.userUID },
                { fieldPath: "shopUID", opStr: "==", value: shop.shopUID },
              ]
            : [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "registeredAt", directionStr: "asc" }],
        startAt,
        endAt,
      });

      // 送信日時でソート
      results.sort((a, b) => {
        if (a.sentAt.seconds > b.sentAt.seconds) return -1;
        if (a.sentAt.seconds < b.sentAt.seconds) return 1;
        return 0;
      });

      // 現在の店舗情報に無い所属を探す
      this.positions = shop.positions.slice();
      for (const item of results) {
        const user = this.users.find((e) => e.id == item.userUID);
        item.position = user ? user.position : "その他";
        item.isNormalForReport = !item.checkItems.some((e) => e.checkResult == "NG");
        if (!this.positions.includes(item.position)) this.positions.push(item.position);
      }

      // 所属別に回答を集計
      this.positions = this.positions.map((p) => {
        return {
          text: p,
          items: results.filter((e) => e.position == p),
          unapprovedCount: results.filter((e) => e.position == p && e.approverName == "").length,
        };
      });

      this.loading = false;
      this.selected = [];
    },
  },
};
</script>

<style scoped>
::v-deep .v-tabs-bar {
  height: 38px;
  background-color: transparent !important;
  border-bottom: solid 1px #0000001f !important;
}

::v-deep .v-tabs-items {
  background-color: transparent;
}

::v-deep .v-window {
  overflow: initial;
}

::v-deep .v-slide-group__prev,
::v-deep .v-slide-group__next {
  min-width: 38px !important;
}
</style>
