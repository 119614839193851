<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col align-self="center">
        <span class="text-subtitle-1 text-sm-h6">設定</span>
      </v-col>
      <v-col v-if="currentData.updaterName" class="d-flex justify-end">
        <div class="d-flex flex-column justify-center text-caption text--secondary">
          <span>更新日：{{ formatDate(currentData.updatedAt, "YYYY/MM/DD") }}</span>
          <span>更新者：{{ currentData.updaterName }}</span>
        </div>
      </v-col>
    </v-row>

    <!-- タブ部分 -->
    <v-tabs v-model="tab">
      <v-tab>点検票</v-tab>
      <v-tab>車両</v-tab>
      <v-tab>登録地点</v-tab>
    </v-tabs>

    <!-- タブ内容 -->
    <v-tabs-items v-model="tab" mandatory>
      <v-tab-item>
        <SheetSetteing
          :loading="loading"
          :editData="editData"
          :currentData="currentData"
          :storeCheckSheet="storeCheckSheet"
        />
      </v-tab-item>
      <v-tab-item>
        <DrivingSetteing
          :loading="loading"
          :editData="editData"
          :currentData="currentData"
          itemKey="cars"
          :storeCheckSheet="storeCheckSheet"
        />
      </v-tab-item>
      <v-tab-item>
        <DrivingSetteing
          :loading="loading"
          :editData="editData"
          :currentData="currentData"
          itemKey="checkPoints"
          :storeCheckSheet="storeCheckSheet"
        />
      </v-tab-item>
    </v-tabs-items>

    <DialogSendError ref="sendErrorDialog" />
    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-container>
</template>

<script>
import firebase from "../../plugins/firebase";
import { db } from "../../plugins/firebase";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import DrivingSetteing from "../templates/DrivingSetteing";
import SheetSetteing from "../templates/SheetSetteing";

export default {
  components: {
    DrivingSetteing,
    SheetSetteing,
  },
  mixins: [calcDate, dbProcess],
  data: () => ({
    tab: 0,
    loading: false,
    editData: { inputtableItem: {}, requiredItem: {} },
    currentData: {},
    message: "",
    messageDialog: false,
  }),
  created: function () {
    this.$emit("created");
  },
  activated: function () {
    this.loadCheckSheet();
  },
  methods: {
    /**
     * DBから点検票情報を取得
     */
    async loadCheckSheet() {
      this.loading = true;

      const shop = this.$store.getters.getShop;
      const sheets = await this.getQueryDoc({
        collection: "alcoholCheckSheets",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
      });
      const sheet = sheets[0];

      this.editData = {
        id: sheet ? sheet.id : "",
        inputtableItem: sheet?.inputtableItem
          ? sheet.inputtableItem
          : {
              carName: false,
              destinationName: false,
              meter: false,
              mileage: false,
              refillQuantity: false,
              pointName: false,
              checkMethod: false,
              concentration: false,
              camera: false,
            },
        requiredItem: sheet?.requiredItem
          ? sheet.requiredItem
          : {
              carName: false,
              destinationName: false,
              meter: false,
              mileage: false,
              refillQuantity: false,
              pointName: false,
              checkMethod: false,
              concentration: false,
              camera: false,
            },
        checkItems: sheet?.checkItems
          ? sheet.checkItems
          : [
              { content: "酒気帯びの有無", required: false },
              { content: "日常点検の実施状況", required: false },
              { content: "疾病、疲労、睡眠不足等の状況", required: false },
            ],
        checkPoints: sheet?.checkPoints ? sheet.checkPoints : [],
        cars: sheet?.cars ? sheet.cars : [],
        updatedAt: sheet ? sheet.updatedAt : "",
        updaterName: sheet ? sheet.updaterName : "",
      };
      this.currentData = JSON.parse(JSON.stringify(this.editData));

      this.loading = false;
    },

    /**
     * 点検票情報をDBに保存
     */
    async storeCheckSheet(storeData) {
      // オフライン時の処理
      if (!navigator.onLine) {
        this.$refs.sendErrorDialog.open("offline");
        return false;
      }

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
      const isCreate = !this.editData.id;
      const storeId = this.editData.id || db.collection("alcoholCheckSheets").doc().id;

      storeData.shopUID = shop.shopUID;
      storeData.updatedAt = serverTimestamp;
      storeData.updaterName = user.name;

      // 新規登録の場合
      if (isCreate) storeData.createdAt = serverTimestamp;

      // DB登録
      const result = await this.writeTransaction([
        {
          method: isCreate ? "set" : "update",
          collection: "alcoholCheckSheets",
          docId: storeId,
          data: storeData,
        },
      ]);

      // 送信失敗の場合
      if (result.status !== "success") {
        const type = result.message == "Connection failed." ? "unstable" : "unexpected";
        this.$refs.sendErrorDialog.open(type);
        return false;
      }

      if (isCreate) this.editData.id = storeId;

      this.message = "設定を保存しました。";
      this.messageDialog = true;

      this.editData.updatedAt = new Date();
      this.editData.updaterName = user.name;
      this.currentData = JSON.parse(JSON.stringify(this.editData));
    },
  },
};
</script>

<style scoped>
::v-deep .v-tabs-bar {
  height: 38px;
  background-color: transparent !important;
  border-bottom: 1px solid #0000001f;
}

.theme--light.v-tabs .v-tab--active:focus::before {
  opacity: 0 !important;
}
.theme--light.v-tabs .v-tab--active:hover::before,
.theme--light.v-tabs .v-tab--active::before {
  opacity: 0 !important;
}

::v-deep .v-tabs-items {
  background-color: transparent !important;
}

::v-deep .v-item-group {
  width: 100%;
}

::v-deep .v-window {
  overflow: initial;
}
</style>
